import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';

const App = () => (
    <BrowserRouter>
        <AppRoutes />
    </BrowserRouter>
);

export default App;
