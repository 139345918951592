import { Routes, Route } from 'react-router-dom';
import { ReactElement, Suspense, lazy } from 'react';

type RouteItem = {
    path: string;
    component: ReactElement<any, any>;
};

const TrackingMap = lazy(() => import('../pages/TrackingMap'));
const RatingShipment = lazy(() => import('../pages/RatingShipment'));

const ROUTES_ARRAY: RouteItem[] = [
    {
        path: '/',
        component: <TrackingMap />,
    },
    {
        path: '/:id',
        component: <TrackingMap />,
    },
    {
        path: '/rating/:idTracking',
        component: <RatingShipment />,
    },
];
export const AppRoutes = () => (
    <Suspense>
        <Routes>
            {ROUTES_ARRAY.map((route) => (
                <Route key={route.path} path={route.path} element={route.component} />
            ))}
        </Routes>
    </Suspense>
);

export default AppRoutes;
